import React, { useState } from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import useDebounce from '../../../hooks/useDebounce';
import { TableCustom, StatePagination } from '../../../components/common';
import formatDate from '../../../components/common/formatDate';
import { CustomIconButton } from '../../../components/common/button/CustomIconButton';
import Add from '@mui/icons-material/Add';
import StateCustomTable from '../../../components/common/StateCustomTable';

interface IPart {
  _id?: string;
  item_no: string | null;
  item_description: string | null;
  createdAt?: Date;
}

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Item Number', rowspan: 1, colspan: 1 },
    { text: 'Item Description', rowspan: 1, colspan: 1 },
    { text: 'Created At', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [{ value: 'item_no', name: 'A-Z' }];

const ProcessPartTable = ({ process, setIsPartModal }: { process: any; setIsPartModal: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const [page, setPage] = useState(1);
  const [partData, setPartData] = useState<IPart[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<string>('item_no');

  const fetchPartData = () => {
    axios
      .get(
        `/api/itemMasterRoute?page=${page}&sortBy=${filter}&search=${search}&perPage=${10}`,
      )
      .then((res) => {
        setPartData(res.data?.results);
        setTotalPages(res.data?.pages);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchPartData, 300, [page, filter, search]);

  return (
    <StateCustomTable
      filterOptions={filterOptions}
      {...{ search, setSearch, filter, setFilter }}
      label="Search Part"
    >
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {partData &&
            partData.map((item: IPart, index: number) => (
              <TableRow key={item?._id}>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">{item.item_no}</TableCell>
                <TableCell align="center">{item.item_description}</TableCell>
                <TableCell align="center">
                  {formatDate(item?.createdAt || '')}
                </TableCell>
                <TableCell align="center">
                  <CustomIconButton
                    sx={{ p: 0 }}
                    tooltipTitle={'add'}
                    onClick={() => {
                      process.item_code = item.item_no;
                      setIsPartModal(false);
                    }}
                  >
                    <Add style={{ color: '#343a40' }} />
                  </CustomIconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </TableCustom>
      <StatePagination page={page} totalPage={totalPages} setPage={setPage} />
    </StateCustomTable>
  );
};

export default ProcessPartTable;