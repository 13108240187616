import { useEffect, useState } from 'react';
import { MenuItem } from '@mui/material';
import axios from 'axios';
import { Table } from '../../../../../components/ui/renderTable/styled';
import CustomSelect from '../../../../../components/common/SelectField';
import { TableInput } from '../../../../../components/common';

const ScheduleTable = (props: any) => {
  const { process, isView } = props;
  const [_, setRender] = useState(false);
  const [rejectionReasons, setRejectionReasons] = useState([]);

  useEffect(() => {
    axios
      .get('/api/listOfSchduleDowntimeRoute/autoComplete/data')
      .then((res) => {
        if (res?.data) {
          setRejectionReasons(res?.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const calProcessRejQty = () => {
    let scheduleMin = 0;
    if (process?.schedule_details?.length > 0) {
      process.schedule_details.forEach((item: any) => {
        scheduleMin = scheduleMin + (+item.schedule_min || 0);
      });
    }
    process.schedule_down_time = scheduleMin;
  };
  return (
    <Table style={{ width: '80vh' }}>
      <thead>
        <tr>
          <th>S.NO.</th>
          <th>DOWN TIME REASONS</th>
          <th>DOWNTIME (in minutes)</th>
        </tr>
      </thead>
      <tbody>
        {process?.schedule_details?.map((item: any, index: number) => (
          <tr>
            <th>{index + 1}</th>
            <td style={{ minWidth: '300px', textAlign: 'center' }}>
              {isView ? (
                item.reason
              ) : (
                <CustomSelect
                  name="reason"
                  value={item.reason}
                  onChange={(e) => {
                    item.reason = e.target.value;
                    setRender((prev) => !prev);
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  {rejectionReasons.map((reason: any) => (
                    <MenuItem value={reason.name}>{reason.name}</MenuItem>
                  ))}
                </CustomSelect>
              )}
            </td>
            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="schedule_min"
                key="schedule_min"
                type="number"
                value={item.schedule_min}
                onChange={(e) => {
                  item.schedule_min = e.target.value;
                  calProcessRejQty();
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th colSpan={2}>TOTAL DOWNTIME</th>
          <th>{process.schedule_down_time}</th>
        </tr>
      </tfoot>
    </Table>
  );
};

export default ScheduleTable;
