import React, { useState, useEffect } from 'react';
import { MenuItem } from '@mui/material';
import CustomSelect from '../../../components/common/SelectField';

const ProcessStageSelect = ({ process, setRender, isRowDisable, isView }:{process:any, setRender:any, isRowDisable:any, isView:any}) => {
  const [currentValue, setCurrentValue] = useState('');

  useEffect(() => {
    setCurrentValue(process?.stage ? process.stage.trim().toLowerCase() : '');
  }, [process?.stage]);

  const handleChange = (e:any) => {
    const newValue = e.target.value;
    process.stage = newValue; // Store the selected value as-is in process.stage
    setCurrentValue(newValue.trim().toLowerCase());
    setRender((prev:any) => !prev);
  };

  const menuItems = [
    { value: '', label: 'None' },
    { value: 'mandrel forging', label: 'Mandrel Forging' },
    { value: 'rivet body forging', label: 'Rivet Body Forging' },
    { value: 'assembly', label: 'Assembly' },
  ];

  // Regex pattern to match our predefined options (now in lowercase)
  const optionsPattern = /^(|mandrel forging|rivet body forging|assembly)$/;

  return (
    <CustomSelect
      name={'stage'}
      value={currentValue}
      sx={{ width: '250px' }}
      onChange={handleChange}
      disabled={isRowDisable || isView}
    >
      {optionsPattern.test(currentValue) ? (
        menuItems.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))
      ) : (
        <>
          <MenuItem value={currentValue}>{process?.stage || currentValue}</MenuItem>
          {menuItems.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </>
      )}
    </CustomSelect>
  );
};

export default ProcessStageSelect;