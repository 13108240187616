import { Box } from '@mui/material';
import { RenderTable } from '../../../components/ui';
import TBody from './TBody';
import THead from './THead';
import TableScroll from '../../../components/ui/renderTable/TableScroll';
interface IProps {
  moduleData: any;
  setModuleData: any;
  isView: boolean;
  handelDeleteRows: (index: number) => void;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setPartIdx: React.Dispatch<React.SetStateAction<number>>;
  onSubmit: () => void;
}

const TController = ({
  handelDeleteRows,
  moduleData,
  onSubmit,
  isView,
  setIsOpenPopup,
  setPartIdx,
  setModuleData,
}: IProps) => {
  return (
    <Box>
      <RenderTable scroll="horizontal">
        <TableScroll>
        <THead
          {...{
            moduleData,
            isView,
            setModuleData,
          }}
        />
        <TBody
          {...{
            onSubmit,
            setPartIdx,
            setIsOpenPopup,
            handelDeleteRows,
            moduleData,
            isView,
          }}
        />
        </TableScroll>
      </RenderTable>
    </Box>
  );
};

export default TController;
