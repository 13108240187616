import { TableCompHead } from '../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  return (
    <thead>
      <TableCompHead
        colSpan={7}
        rowSpan={3}
        labels={[]}
        values={[]}
        heading="DAY SCHEDULING"
      />
      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'S. NO.',
          'START TIME',
          'END TIME',
          'TASK',
          'TIME (MINS)',
          'ACTION',
        ].map((head) => (
          <th key={head} style={{ textTransform: 'uppercase' }}>
            {head}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
