import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import {
  CustomButton,
  UpdateButton,
} from '../../../components/common/button/CustomButtons';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import TController from './components/TController';

const CreateDaySchedulingDetails = () => {
  const [moduleData, setModuleData] = useState<any>({});
  const [render, setRender] = useState(false);
  const { id } = useParams();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [downTimes, setDownTimes] = useState([]);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?',
  );

  useEffect(() => {
    axios
      .get(`/api/listOfSchduleDowntimeRoute/autoComplete/data`)
      .then((res) => {
        if (res.data) {
          const options = res.data.map((item: any) => item.name);
          options.push('Working');
          setDownTimes(options);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/daySchedulingRoute/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    await axios
      .put(`/api/daySchedulingRoute/${id}`, moduleData)
      .then((res) => {
        if (res.data) {
          alert('Updated Successfully');
        }
      })
      .catch((err) => console.log(err));
  };

  const handelDeleteRows = async (rowIdx: number) => {
    let data = { ...moduleData };
    data.details.splice(rowIdx, 1);
    setModuleData(data);
  };

  return (
    <>
      <Box>
        <ChildHeader text="DAY SCHEDULING">
          {!isView && (
            <>
              <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                  marginRight: '1rem',
                }}
                onClick={async () => {
                  moduleData.details.push({});
                  setRender((prev) => !prev);
                }}
              >
                ADD ROWS
              </CustomButton>
              <UpdateButton label="Update" onClick={() => handleSumbit()} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          handelDeleteRows,
          downTimes,
        }}
      />
      <DialogSubmit />
    </>
  );
};

export default CreateDaySchedulingDetails;
