import { useEffect, useState } from 'react';
import TController from './components/view/TController';
import { initialState } from './helpers/initialState';
import lodash from 'lodash';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import {
  CustomButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { PersitedDatePicker } from '../../../components/common';
import { Stack } from '@mui/material';
import { Delete } from '@mui/icons-material';
import ModalCustom from '../../../components/common/Modal';
import UnScheduleTable from './components/view/UnScheduleTable';
import ScheduleTable from './components/view/ScheduleTable';

const View = () => {
  const date = useQuery('date');
  const [moduleData, setModuleData] = useState<any>({
    month: date && new Date(date).getMonth() + 1,
    year: date && new Date(date).getFullYear(),
    machine_id: null,
    processes: [],
  });

  const [render, setRender] = useState(false);
  const [useEffecRender, setUseEffectRender] = useState(false);
  const [isOpenPopupForRejections, setIsOpenPopupRejections] = useState(false);
  const [isOpenPopupForScheduleDowntime, setIsOpenPopupForScheduleDowntime] =
    useState(false);
  const [rejectionsIndx, setRejectionsIndx] = useState(0);

  const isView = useQuery('isView') === 'true' ? true : false;

  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?',
  );

  useEffect(() => {
    if (moduleData.machine_id) {
      axios
        .get(
          `/api/dailyProduction/monthly/${moduleData.machine_id._id}?date=${date}`,
        )
        .then((res) => {
          if (res.data) {
            setModuleData(res.data);
          }
        })
        .catch((err) => {
          setModuleData((prevData: any) => ({
            ...prevData,
            month: date ? new Date(date).getMonth() + 1 : undefined,
            year: date ? new Date(date).getFullYear() : undefined,
            machine_id: moduleData.machine_id,
            ...lodash.cloneDeep(initialState),
          }));
          console.log(err);
        });
    } else {
      setModuleData((prevData: any) => ({
        ...prevData,
        month: date ? new Date(date).getMonth() + 1 : undefined,
        year: date ? new Date(date).getFullYear() : undefined,
        machine_id: null,
        processes: [],
      }));
    }
  }, [date, useEffecRender]);

  const handleSumbit = async () => {
    if (!date || !moduleData.machine_id) {
      alert('machine date required');
      return;
    }

    const ans = await confirmSubmit();
    if (!ans) return;
    if (moduleData._id) {
      await axios
        .put(`/api/dailyProduction/${moduleData._id}`, moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Updated Successfully');
            setUseEffectRender((prev) => !prev);
          }
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/dailyProduction', moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('created Successfully');
            setUseEffectRender((prev) => !prev);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handelDeleteRows = async (rowIdx: number) => {
    let data = { ...moduleData };
    data.processes.splice(rowIdx, 1);
    setModuleData(data);
  };

  return (
    <>
      <Box>
        <ChildHeader text={'RIVET BODY REPORT'}>
          {!isView && (
            <>
              <CustomButton
                sx={{ marginRight: '0.8rem' }}
                onClick={() => {
                  setModuleData((prevData: any) => ({
                    ...prevData,
                    month: date ? new Date(date).getMonth() + 1 : undefined,
                    year: date ? new Date(date).getFullYear() : undefined,
                    machine_id: moduleData.machine_id,
                    ...lodash.cloneDeep(initialState),
                  }));
                }}
                icon={<Delete />}
              >
                RESET
              </CustomButton>

              <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                }}
                onClick={async () => {
                  if (!moduleData.machine_id)
                    return alert('please choose machine first!');
                  const values = { ...moduleData };
                  values.processes.push({
                    rejection_details: Array.from({ length: 10 }, () =>
                      lodash.cloneDeep({ reason: null, rej_qty: null }),
                    ),
                  });
                  setModuleData(values);
                  setRender((prev) => !prev);
                }}
              >
                ADD ROWS
              </CustomButton>
              <SubmitButton label="Submit" onClick={() => handleSumbit()} />
            </>
          )}
        </ChildHeader>
      </Box>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'start',
          alignItems: 'center',
          gap: 2,
          m: 1,
        }}
      >
        <PersitedDatePicker label="Search By Year" views={['year', 'month']} />
      </Stack>

      <TController
        {...{
          moduleData,
          isView,
          handelDeleteRows,
          setUseEffectRender,
          setIsOpenPopupRejections,
          setIsOpenPopupForScheduleDowntime,
          setRejectionsIndx,
        }}
      />
      <ModalCustom
        title="Unschedule Downtime"
        openModal={isOpenPopupForRejections}
        closeModal={() => {
          setIsOpenPopupRejections(false);
        }}
      >
        <UnScheduleTable
          process={moduleData.processes[rejectionsIndx]}
          isView={isView}
        />
      </ModalCustom>
      <ModalCustom
        title="Schedule Downtime"
        openModal={isOpenPopupForScheduleDowntime}
        closeModal={() => {
          setIsOpenPopupForScheduleDowntime(false);
        }}
      >
        <ScheduleTable
          process={moduleData.processes[rejectionsIndx]}
          isView={isView}
        />
      </ModalCustom>

      <DialogSubmit />
    </>
  );
};

export default View;
