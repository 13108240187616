import moment from 'moment';
import { calMinute } from '../../../../utils/helpers';
export const updatePlanningRecords = (
  planningRecords: any,
  productionDataByPart: any,
) => {
  let index = 0; // Initialize the index
  const modifiedPlanningRecords: any[] = [...planningRecords];
  for (const planningRecord of planningRecords) {
    const jobNumber = planningRecord.job_number;
    const partData = productionDataByPart[jobNumber] || {};

    //calculate next row start date and start time
    calculateNextRowStartDateAndTime(index, modifiedPlanningRecords);

    // Retrieve stroke rate
    const strokeRate = planningRecord.strokeRate;

    // Calculate required values
    const {
      totalTimeRequired,
      jobCompletion,
      predictedCompletionDate,
      actualCompletionDate,
    } = calculateCompletionData(planningRecord, partData, strokeRate);

    const totalDownTime = calculateTotalDownTime(
      totalTimeRequired,
      planningRecord.start_time,
      planningRecord.day_id,
    );
    // Calculate predicted completion time
    const predictedCompletionTime = calculateCompletionTime(
      totalTimeRequired,
      planningRecord.start_time,
      totalDownTime,
    );

    // Update the plan
    modifiedPlanningRecords[index].actual_qty = partData?.actualQty || 0;
    modifiedPlanningRecords[index].stroke = strokeRate?.stroke || 0;
    modifiedPlanningRecords[index].stroke_id = strokeRate?._id || null;
    modifiedPlanningRecords[index].total_time_required = totalTimeRequired || 0;
    modifiedPlanningRecords[index].total_downtime = totalDownTime || 0;
    modifiedPlanningRecords[index].job_completion = jobCompletion;
    modifiedPlanningRecords[index].records = partData.records || [];
    modifiedPlanningRecords[index].predicted_completion_date =
      predictedCompletionDate || null;
    modifiedPlanningRecords[index].predicted_completion_time =
      predictedCompletionTime;
    modifiedPlanningRecords[index].actual_completion_date =
      actualCompletionDate;

    index++; // Increment the index manually
  }
  return modifiedPlanningRecords;
};

const calculateTotalDownTime = (
  totalTimeRequired: number,
  start_time: any,
  day_id: any,
) => {
  const totalTime = totalTimeRequired;

  // Create a moment object from the startTime
  const startMoment = moment(start_time);

  const predictedCompletionTime = startMoment.add(totalTime, 'minutes');

  const predictedTime = predictedCompletionTime.toDate();
  const resetPredictedTime = new Date(
    1970,
    0,
    1,
    predictedTime.getHours(),
    predictedTime.getMinutes(),
    predictedTime.getSeconds(),
  );

  const planningStartTime = new Date(start_time);

  // Extract the details array from the day_id object.
  const details = day_id?.details || [];

  // Calculate the total downtime in minutes for tasks where item.start_time > planningStartTime.
  const totalDownTime = details.reduce((acc: any, item: any) => {
    const itemStartTime = new Date(item.start_time);

    // Reset both planningStartTime and itemStartTime to the same date for time comparison.
    const resetPlanningStartTime = new Date(
      1970,
      0,
      1,
      planningStartTime.getHours(),
      planningStartTime.getMinutes(),
      planningStartTime.getSeconds(),
    );
    const resetItemStartTime = new Date(
      1970,
      0,
      1,
      itemStartTime.getHours(),
      itemStartTime.getMinutes(),
      itemStartTime.getSeconds(),
    );

    // Skip tasks that start after or at the predicted completion time.
    if (resetPredictedTime <= resetItemStartTime) {
      return acc;
    }

    // Only consider tasks that are not "Working" and have resetItemStartTime >= resetPlanningStartTime.
    if (
      item.task !== 'Working' &&
      resetItemStartTime >= resetPlanningStartTime
    ) {
      return acc + calMinute(item.start_time, item.end_time);
    }

    return acc;
  }, 0);

  return totalDownTime;
};

// helper function to calculate next row start_date and start_time

const addTwoHours = (date: string, time: string) => {
  if (!date || !time) return { datetime: null };

  const datetime = moment(date).startOf('day'); // Set to midnight of the given date

  if (!datetime.isValid()) return { datetime: null };

  const timeMoment = moment(time).format('HH:mm');

  if (!timeMoment) return { datetime: null };

  datetime.set({
    hour: moment(time).hour(),
    minute: moment(time).minute(),
  });

  const updatedDatetime = datetime.add(2, 'hours');

  return {
    datetime: updatedDatetime.toDate(), // Final Date object with added time
  };
};

const calculateNextRowStartDateAndTime = (index: number, data: any) => {
  if (index > 0 && data[index - 1]) {
    const prevRow = data[index - 1];
    if (prevRow.actual_completion_date && prevRow.actual_completion_time) {
      const { datetime } = addTwoHours(
        prevRow.actual_completion_date,
        prevRow.actual_completion_time,
      );
      data[index].start_date = datetime;
      data[index].start_time = datetime;
    } else {
      const { datetime } = addTwoHours(
        prevRow.predicted_completion_date,
        prevRow.predicted_completion_time,
      );
      data[index].start_date = datetime;
      data[index].start_time = datetime;
    }
  }
};

// Helper function to calculate completion data
const calculateCompletionData = (
  planningRecord: any,
  partData: any,
  strokeRate: any,
) => {
  let totalTimeRequired = 0;
  let jobCompletion = 0;
  let predictedCompletionDate = null;
  let actualCompletionDate = planningRecord.actual_completion_date;

  if (strokeRate && planningRecord.planned_qty > 0) {
    if (strokeRate.stroke) {
      totalTimeRequired = parseInt(
        planningRecord.planned_qty / strokeRate.stroke + '',
      );
    }

    if (partData.actualQty >= 0 && planningRecord.planned_qty >= 0) {
      jobCompletion = parseInt(
        ((partData.actualQty / planningRecord.planned_qty) * 100).toFixed(2),
      );
    }

    if (totalTimeRequired >= 0 && planningRecord.start_date) {
      const startDate = new Date(planningRecord.start_date);
      const totalTimeInMs = totalTimeRequired * 60 * 1000; // Convert minutes to milliseconds
      predictedCompletionDate = new Date(startDate.getTime() + totalTimeInMs);
    }

    if (parseInt(jobCompletion + '') >= 100 && !actualCompletionDate) {
      actualCompletionDate = new Date();
    }

    if (parseInt(jobCompletion + '') < 100) {
      actualCompletionDate = null;
    }
  }

  return {
    totalTimeRequired,
    jobCompletion,
    predictedCompletionDate,
    actualCompletionDate,
  };
};

const calculateCompletionTime = (
  totalTimeRequired: any,
  startTime: any,
  totalDowntime: any,
) => {
  // Check if the necessary inputs are provided
  if (!totalTimeRequired || !startTime) {
    return null;
  }

  // Calculate total time including downtime
  const totalTime = totalTimeRequired + (parseInt(totalDowntime) || 0);

  // Create a moment object from the startTime
  const startMoment = moment(startTime);

  // Add the total time in minutes to the start time
  const predictedCompletionTime = startMoment.add(totalTime, 'minutes');

  return predictedCompletionTime.toDate();
};
