import { useState } from 'react';
import { TableInput } from '../../../../../components/common';

interface Ifoot {
  moduleData: any;
  isView: boolean;
}
const TFoot = ({ moduleData, isView }: Ifoot) => {
  const [render, setRender] = useState(false);

  return (
    <tfoot>
      <tr>
        <th colSpan={13} align="left">
          Prepared By:{' '}
          <TableInput
            isView={isView}
            name="report_prepared_by"
            type="text"
            value={moduleData.report_prepared_by}
            onChange={(e) => {
              moduleData.report_prepared_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={13} align="left">
          Checked By:
          <TableInput
            isView={isView}
            name="report_approved_by"
            type="text"
            value={moduleData.report_approved_by}
            onChange={(e) => {
              moduleData.report_approved_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={13} align="left">
          Signature:{' '}
          <TableInput
            isView={isView}
            name="sign1"
            type="text"
            value={moduleData.sign1}
            onChange={(e) => {
              moduleData.sign1 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={13} align="left">
          Singature:
          <TableInput
            isView={isView}
            name="sign2"
            type="text"
            value={moduleData.sign2}
            onChange={(e) => {
              moduleData.sign2 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={13} align="left">
          Name:{' '}
          <TableInput
            isView={isView}
            name="name1"
            type="text"
            value={moduleData.name1}
            onChange={(e) => {
              moduleData.name1 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={13} align="left">
          Name:
          <TableInput
            isView={isView}
            name="name2"
            type="text"
            value={moduleData.name2}
            onChange={(e) => {
              moduleData.name2 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={13} align="left">
          Designation:{' '}
          <TableInput
            isView={isView}
            name="designation1"
            type="text"
            value={moduleData.designation1}
            onChange={(e) => {
              moduleData.designation1 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={13} align="left">
          Designation:
          <TableInput
            isView={isView}
            name="designation2"
            type="text"
            value={moduleData.designation2}
            onChange={(e) => {
              moduleData.designation2 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
