import { Grid } from '@mui/material';
import { TableInput } from '../../../../../components/common';
import moment from 'moment';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
  ViewIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TimePicker } from '@mui/x-date-pickers';
import { convToTime } from '../../../../../utils/helpers';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  setIsOpenPopup: any;
  setPartIdx: any;
  setIsOpenSummary: any;
  handleSortChange: any;
  handleDataChange: any;
  handleSumbit: any;
  dayScheduleData: any;
}

const getBackgroundColor = (jobCompletion: number) => {
  if (jobCompletion === 100) return '#8ecae6';
  if (jobCompletion >= 90) return '#a7c957';
  if (jobCompletion >= 50) return '#e9c46a';
  return '#f28482';
};

const TBody = ({
  moduleData,
  isView,
  handelDeleteRows,
  setIsOpenPopup,
  setPartIdx,
  setIsOpenSummary,
  handleSortChange,
  handleDataChange,
  handleSumbit,
  dayScheduleData,
}: IProps) => {
  return (
    <tbody>
      {moduleData.map((process: any, index: number) => {
        return (
          <tr key={index + 'module data'}>
            <td width={'100px'} style={{ fontWeight: 'bold' }}>
              {process.job_number}
            </td>
            <td style={{ minWidth: '100px' }}>
              {index === 0 ? (
                <span>{process.serial_number}</span>
              ) : (
                <TableInput
                  isView={isView}
                  name="serial_number"
                  type="number"
                  value={process.serial_number}
                  onChange={(e) =>
                    handleSortChange(index, parseInt(e.target.value))
                  }
                />
              )}
            </td>
            <td style={{ minWidth: '200px' }}>
              <AutocompleteMuiCustom
                id="day"
                label=""
                option_name="day"
                arrayofObj={dayScheduleData || []}
                value={process?.day_id || ''}
                onChange={(e, value) => {
                  handleDataChange(index, 'day_id', value);
                }}
              />
            </td>
            <td style={{ minWidth: '120px' }}>
              <TableInput
                isView={isView}
                name="planned_qty"
                type="number"
                value={process.planned_qty || ''}
                onChange={(e) => {
                  handleDataChange(index, 'planned_qty', e.target.value);
                }}
              />
            </td>

            {process.planned_qty > 0 ? (
              <td style={{ width: '250px', minWidth: '250px' }}>
                {isView ? (
                  process?.part_id && process?.part_id?.item_no
                ) : (
                  <Grid container direction="row" alignItems={'center'}>
                    <Grid item xs={10}>
                      <p style={{ fontSize: '1.6rem' }}>
                        {process?.part_id?.item_no ||
                          process?.partNumber ||
                          'Pick Part Number'}
                      </p>
                    </Grid>
                    <Grid item xs={2}>
                      {process.part_id ? (
                        <RemoveIconButton
                          tooltipTitle="remove part"
                          onClick={async () => {
                            process.part_id = null;
                            process.Number = null;
                            process.partName = null;
                            process.tool_item_ids = [];
                            process.too_ids = [];
                            process.weight_pc = null;
                            await handleSumbit();
                          }}
                        />
                      ) : (
                        <AddIconButton
                          tooltipTitle="add part"
                          onClick={() => {
                            setIsOpenPopup(true);
                            setPartIdx(index);
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                )}
              </td>
            ) : (
              <td style={{ width: '250px', minWidth: '250px' }}>
                <p style={{ fontSize: '1.6rem', color: 'red' }}>
                  First fill the planned qty
                </p>
              </td>
            )}

            <td
              style={{
                minWidth: '120px',
                backgroundColor: getBackgroundColor(process.job_completion),
              }}
            >
              {process.job_completion}
            </td>

            <td style={{ minWidth: '120px' }}>{process.actual_qty}</td>
            <td style={{ minWidth: '120px' }}>{process.stroke}</td>
            <td style={{ minWidth: '120px' }}>{process.total_time_required}</td>
            <td style={{ minWidth: '120px' }}>{process.total_downtime}</td>

            <td style={{ minWidth: '170px' }}>
              {index === 0 ? (
                <DatePickerMui
                  label=""
                  value={moment(process.start_date || null)}
                  onChange={(date) => {
                    handleDataChange(index, 'start_date', date);
                  }}
                />
              ) : (
                <span>{formatDate(process.start_date)}</span>
              )}
            </td>

            <td style={{ minWidth: '150px' }}>
              {index === 0 ? (
                <TimePicker
                  value={moment(process.start_time || null)}
                  onChange={(date) => {
                    handleDataChange(index, 'start_time', date);
                  }}
                />
              ) : (
                <span>{convToTime(process.start_time)}</span>
              )}
            </td>

            <td style={{ minWidth: '120px' }}>
              {formatDate(process.predicted_completion_date)}
            </td>
            <td style={{ minWidth: '120px' }}>
              {convToTime(process.predicted_completion_time)}
            </td>
            <td style={{ minWidth: '120px' }}>
              {formatDate(process.actual_completion_date)}
            </td>
            <td style={{ minWidth: '160px' }}>
              {process.actual_completion_date && (
                <TimePicker
                  value={moment(process.actual_completion_time || null)}
                  onAccept={async (date: any) => {
                    handleDataChange(index, 'actual_completion_time', date);
                  }}
                />
              )}
            </td>
            <td>
              <ViewIconButton
                tooltipTitle="Daywise Summary"
                onClick={() => {
                  setPartIdx(index);
                  setIsOpenSummary(true);
                }}
              />
            </td>
            <td>
              <DeleteIconButton
                disabled={index === 0 && process._id}
                color={index === 0 && process._id && '#6c757d'}
                tooltipTitle={'delete'}
                onClick={() => handelDeleteRows(process._id, index)}
              />
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default TBody;
