import React, { useState } from 'react';
import { Stack, TableBody } from '@mui/material';
import axios from 'axios';

import { AddButton } from '../../components/common/button/CustomButtons';
import ChildHeader from '../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import { SearchBar } from '../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../components/common';
import useDebounce from '../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  CardIconButton,
} from '../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../components/common/Modal';
import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';

import { useNavigate } from 'react-router-dom';
import TableScroll from '../../components/ui/renderTable/TableScroll';
import {
  StyledTableCell,
  StyledTableRow,
} from '../../components/ui/module.styled';
import { convToTime } from '../../utils/helpers';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  border: '1px solid #343a40',
};

const tableHeaderObj = [
  [
    { text: 'SR NO.', rowspan: 1, colspan: 1, style: { ...style1 } },
    {
      text: 'SHIFT',
      rowspan: 1,
      colspan: 1,
      style: { ...style1 },
    },
    {
      text: 'DAY SCHEDULING',
      rowspan: 1,
      colspan: 1,
      style: { ...style1 },
    },
    { text: 'ACTION', rowspan: 1, colspan: 1, style: { ...style1 } },
  ],
];

const DaySchedulingList = () => {
  const [data, setData] = useState<any[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [id, setId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search');
  const filter = useQuery('filter');
  const page = useQuery('page') || 1;
  const navigate = useNavigate();
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE ',
    'Are you sure you want to delete this?',
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/daySchedulingRoute?page=${page}&&sortBy=${filter}&search=${search}&perPage=${perPage}`,
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteDocument = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/daySchedulingRoute/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal]);
  return (
    <>
      <ChildHeader text="DAY SCHEDULING">
        <AddButton onClick={() => setIsModal(true)} label="NEW SCHEDULE" />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search day" />
      </SearchFilterContainer>
      <TableScroll>
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {data &&
              data.map((item: any, index: number) => (
                <React.Fragment key={item._id}>
                  <StyledTableRow>
                    <StyledTableCell align="center">
                      {perPage * (+page - 1) + index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {item?.day}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Stack direction="row" sx={{ justifyContent: 'center' }}>
                        <CardIconButton
                          tooltipTitle="SCHEDULE DAY"
                          onClick={() => {
                            navigate(
                              `/common/createDaySchedulingDetails/${item._id}`,
                            );
                          }}
                        />
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Stack direction="row" sx={{ justifyContent: 'center' }}>
                        <EditIconButton
                          tooltipTitle="EDIT"
                          onClick={() => {
                            setId(item?._id || '');
                            setIsModal(true);
                          }}
                        />

                        <DeleteIconButton
                          tooltipTitle="DELETE"
                          onClick={() => {
                            deleteDocument(item?._id || '');
                          }}
                        />
                      </Stack>
                    </StyledTableCell>
                  </StyledTableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </TableCustom>
      </TableScroll>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setId(null);
          setIsModal(false);
        }}
        title="CREATE DAY"
      >
        <Create id={id} setIsModal={setIsModal} {...{ setId }} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default DaySchedulingList;
