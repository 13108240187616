import React, { useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';

import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import { SearchBar } from '../../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { IData } from './Interface';

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'DOWNTIME REASON', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];
const perPage = 10;
const DefectList = () => {
  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [defectId, setDefectId] = useState<string | null>(null);
  const [totalPages, setTotalPages] = useState(0);

  const search = useQuery('search');
  const page = useQuery('page') || 1;

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?',
  );
  const fetchData = async () => {
    const res: any = await axios
      .get(
        `/api/unscheduleDefectRoutes?page=${page}&search=${search}&perPage=${perPage}`,
      )
      .catch((err) => {
        console.log(err);
      });
    if (res.data) {
      setData(res?.data?.results);
      setTotalPages(res?.data?.pages);
    }
  };

  const deleteDefect = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/unscheduleDefectRoutes/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal]);

  return (
    <>
      <ChildHeader text="DOWNTIME REASON LIST">
        <AddButton
          onClick={() => setIsModal(true)}
          label="NEW DOWNTIME REASON"
        />
      </ChildHeader>
      <SearchFilterContainer filter={false}>
        <SearchBar label="Search" />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: IData, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item?.defect_name}</TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setDefectId(item?._id || '');
                          setIsModal(true);
                        }}
                      />
                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteDefect(item?._id || '');
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setDefectId(null);
          setIsModal(false);
        }}
        title="DOWNTIME REASON"
      >
        <Create id={defectId} setIsModal={setIsModal} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default DefectList;
