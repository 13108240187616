import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import { TableInput } from '../../../components/common';
import { DeleteIconButton } from '../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../components/common/DatePickerMui';
import AutocompleteMuiCustom from '../../../components/common/AutocompleteMuiCustom';
import axios from 'axios';
import formatDate from '../../../components/common/formatDate';
import CustomSelect from '../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import ProcessPartTable from './ProcessPartTable';
import { CustomIconButton } from '../../../components/common/button/CustomIconButton';
import Add from '@mui/icons-material/Add';
import ModalCustom from '../../../components/common/Modal';
import ProcessStageSelect from './CustomSelectComponent';

const TBodyRow = ({ isView, process, handelDeleteRows, index }: any) => {
  const [render, setRender] = useState(false);
  const isRowDisable = process?.is_added_manually === true ? false : true;
  const [defectData, setDefectData] = useState([]);
  const [machines, setMachines] = useState([]);
  const [isPartModal, setIsPartModal] = useState(false);
  console.log(isView);
  useEffect(() => {
    axios
      .get('/api/defectRoutes/defectList/defectForAutoComplete')
      .then((res) => {
        setDefectData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get('/api/machineRoutes/machine/ForAutocomplete')
      .then((res) => {
        setMachines(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td style={{ minWidth: '150px' }}>
          {isView || isRowDisable ? (
            formatDate(process?.date)
          ) : (
            <DatePickerMui
              value={process?.date ? moment(process?.date) : null}
              onChange={(date) => {
                process.date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </td>
        <td style={{ minWidth: '150px' }}>
          {isRowDisable || isView ? (
            moment(process?.time).format('h:mm:ss a')
          ) : (
            <TimePicker
              // ampm={false}
              value={process?.time ? moment(process?.time) : null}
              onChange={(date: any) => {
                process.time = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </td>
        {/*
        <td>
          <TableInput
            isView={isRowDisable || isView}
            style={{ width: '120px' }}
            name="stage"
            type="text"
            value={process?.stage}
            onChange={(e) => {
              process.stage = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
        */}
        <td>
        <ProcessStageSelect
          process={process}
          setRender={setRender}
          isRowDisable={isRowDisable}
          isView={isView}
        />
        </td>
        <td style={{ minWidth: '200px' }}>
          {isRowDisable ? (
            <span>{process?.machine_no}</span>
          ) : isView ? (
            process?.machine_id?.machine_no
          ) : (
            <AutocompleteMuiCustom
              id="Machine"
              label=""
              key={'machine'}
              option_name="machine_no"
              arrayofObj={machines}
              value={process?.machine_id || ''}
              onChange={(e, value) => {
                process.machine_id = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </td>
        <td>
          <TableInput
            isView={isRowDisable || isView}
            style={{ width: '120px' }}
            name="production_order_no"
            type="text"
            value={process?.production_order_no}
            onChange={(e) => {
              process.production_order_no = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
        
        {/*
        <td>
          <TableInput
            isView={isView || isRowDisable}
            style={{ width: '120px' }}
            name="item_code"
            type="text"
            value={process?.item_code}
            onChange={(e) => {
              process.item_code = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
        */}

        <td>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TableInput
              isView={isView || isRowDisable}
              style={{ width: '120px' }}
              name="item_code"
              type="text"
              value={process?.item_code}
              onChange={(e) => {
                process.item_code = e.target.value;
                setRender((prev) => !prev);
              }}
            />
            {!isView && !isRowDisable && (
              <CustomIconButton
                sx={{ ml: 1 }}
                tooltipTitle={'Select Part'}
                onClick={() => setIsPartModal(true)}
              >
                <Add style={{ color: '#343a40' }} />
              </CustomIconButton>
            )}
          </div>
        </td>

        <td>
          <TableInput
            isView={isView}
            style={{ width: '120px' }}
            name="suspected_qty"
            type="number"
            value={process?.suspected_qty}
            onChange={(e) => {
              process.suspected_qty = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
        {/*
        <td>
          <TableInput
            isView={isView}
            style={{ width: '120px' }}
            name="uom"
            type="string"
            value={process?.uom}
            onChange={(e) => {
              process.uom = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
        */}
        <td>
          <CustomSelect
            name={'uom'}
            value={process?.uom}
            sx={{width: '120px'}}
            onChange={(e) => {
              process.uom = e.target.value;
              setRender((prev) => !prev);
            }}
            disabled = {isRowDisable || isView}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="Kg">Kg</MenuItem>
            <MenuItem value="Pcs">Pcs</MenuItem>
          </CustomSelect>
        </td>


        <td style={{ minWidth: '300px' }}>
          {isView ? (
            process?.defect_id?.defect_name
          ) : (
            <AutocompleteMuiCustom
              id={'defect_name'}
              label=""
              option_name="defect_name"
              arrayofObj={defectData}
              value={process.defect_id || ''}
              onChange={(e, value) => {
                process.defect_id = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </td>
        <td>
          <TableInput
            isView={isView}
            style={{ width: '120px' }}
            name="containment_action"
            type="text"
            value={process.containment_action}
            onChange={(e) => {
              process.containment_action = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td>
          <TableInput
            isView={isView}
            style={{ width: '120px' }}
            name="root_cause"
            type="text"
            value={process.root_cause}
            onChange={(e) => {
              process.root_cause = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td>
          <TableInput
            isView={isView}
            style={{ width: '120px' }}
            name="action_taken"
            type="text"
            value={process.action_taken}
            onChange={(e) => {
              process.action_taken = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td>
          <TableInput
            isView={isView}
            style={{ width: '120px' }}
            name="ok_qty"
            type="number"
            value={process.ok_qty}
            onChange={(e) => {
              process.ok_qty = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td>
          <TableInput
            isView={isView}
            style={{ width: '120px' }}
            name="scrap_qty"
            type="number"
            value={process.scrap_qty}
            onChange={(e) => {
              process.scrap_qty = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td>
          <TableInput
            isView={isView}
            style={{ width: '120px' }}
            name="reworked_qty"
            type="number"
            value={process.reworked_qty}
            onChange={(e) => {
              process.reworked_qty = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td>
          <TableInput
            isView={isView}
            style={{ width: '120px' }}
            name="accepted_qty"
            type="number"
            value={process.accepted_qty}
            onChange={(e) => {
              process.accepted_qty = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>

        <td>
          <TableInput
            isView={isView}
            style={{ width: '120px' }}
            name="qa_sign"
            type="text"
            value={process.qa_sign}
            onChange={(e) => {
              process.qa_sign = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td>
          <TableInput
            isView={isView}
            style={{ width: '120px' }}
            name="prd_sign"
            type="text"
            value={process.prd_sign}
            onChange={(e) => {
              process.prd_sign = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td colSpan={isView === true ? 2 : 1}>
          <TableInput
            isView={isView}
            style={{ width: '120px' }}
            name="remarks"
            type="text"
            value={process.remarks}
            onChange={(e) => {
              process.remarks = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
        {!isView && (
          <td style={{ minWidth: '100px' }}>
            <DeleteIconButton
              tooltipTitle={'delete'}
              onClick={() => handelDeleteRows(index)}
            />
          </td>
        )}
      </tr>

      <ModalCustom
        openModal={isPartModal}
        closeModal={() => setIsPartModal(false)}
        title="Select Part"
      >
        <ProcessPartTable process={process} setIsPartModal={setIsPartModal} />
      </ModalCustom>
    </>
  );
};

export default TBodyRow;
