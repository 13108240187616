import moment from 'moment';
const calMinute = (from: any, to: any) => {
  if (!from || !to) return null;

  // Create Moment.js objects from the time values with consistent format
  const startTimeObj = moment(from);
  const endTimeObj = moment(to);

  // Check if end time is earlier than start time (e.g., spans to the next day)
  if (endTimeObj.isBefore(startTimeObj)) {
    // Add 1 day to the end time to correctly calculate the difference
    endTimeObj.add(1, 'day');
  }

  // Calculate the difference in minutes
  const diffInMinutes = endTimeObj.diff(startTimeObj, 'minutes');
  const modFive = diffInMinutes % 5;

  // Round up if there is a remainder when divided by 5
  return modFive > 0 ? diffInMinutes + (5 - modFive) : diffInMinutes;
};

export default calMinute;
